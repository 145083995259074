<template>
  <Modal>
    <template v-slot:icon>
      <ExclamationIcon class="w-8 h-8" aria-hidden="true" />
    </template>
    <template v-slot:header>
      <span>Update Account Email</span>
    </template>
    <template v-slot:body>
      <span class="font-semibold">Warning: you are updating the email address associated with your account.</span>
      <div class="flex flex-col space-y-2 pt-4">
        <li>You will no longer be able to sign in to your account using the previous email address</li>
        <li>You will need either your account number or access to the new email to access your account</li>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        class="w-full mt-3 button button--small button--outline sm:mt-0"
        @click="close"
      >
        Cancel
      </button>
      <button
        class="w-full button button--small button--success"
        @click="confirm"
      >
        Update
      </button>
    </template>
  </Modal>
</template>

<script>
import { ExclamationIcon } from '@heroicons/vue/outline'
import Modal from '@/components/Modal'

export default {
  name: 'UpdateEmailConfirmation',
  components: {
    ExclamationIcon,
    Modal
  },
  methods: {
    close() {
      this.$emit('modal-close')
    },
    confirm() {
      this.$emit('modal-confirm')
    }
  }
}
</script>

<style scoped>

</style>
