<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="119px" height="119px" viewBox="0 0 119 119" style="enable-background:new 0 0 119 119;" xml:space="preserve" stroke="currentColor" fill="currentColor">
  <g><path d="M116,20.5H3c-1.7,0-3,1.3-3,3v72c0,1.7,1.3,3,3,3h113c1.7,0,3-1.3,3-3v-72C119,21.8,117.7,20.5,116,20.5z M76,31.5
    c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3V31.5z M76,54.5c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8
    c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3V54.5z M53,31.5c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3
    V31.5z M53,54.5c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3V54.5z M30,54.5c0-1.7,1.3-3,3-3h8
    c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3V54.5z M21,62.5c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
    c1.7,0,3,1.3,3,3V62.5z M21,39.5c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3V39.5z M96,85.5
    c0,1.7-1.3,3-3,3H26c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h67c1.7,0,3,1.3,3,3V85.5z M113,62.5c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3
    v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3V62.5z M113,39.5c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3
    V39.5z"/></g>
  <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
  </svg>
  <!-- eslint-enable max-len-->
</template>

<script>
export default {
  name: 'KeyboardIcon'
}
</script>
